import React from "react";
import Barcode from "react-barcode";
import WFLogo from "./../assets/wf-logo.svg";
import AmbalajGarantieLogo from "./../assets/sgr-logo.png";

import "./../App.css";
// export default function Label({ producer, model, type, quantity, alcohol, country, year, barcode }) {
export default function Label(props) {
  const {wine } = props
  const {
    producer,
    model,
    type,
    quantity,
    alcohol,
    country,
    year,
    barcode,
  } = wine;
  // const width = 210 / 3; // 70
  // const height = 297 / 8; // 37.125
  const width = 63.7;
  const height = 38.3;
  const padding = 3;
  // const padding = 4;
  const leftBannerWidth = 2.35;
  // const largeFontSize = 2.2;
  const largeFontSize = 2.4;
  return (
    <div
      className="label"
      style={{
        display: "flex",
        position: "absolute",
        top: "0",
        left: "0",
        width: `${width}mm`,
        height: `${height}mm`,
        // border: "1px solid black",
        padding: `${padding}mm`,
      }}
    >
      <div
        style={{
          paddingLeft: `${leftBannerWidth + 0.4}mm`,
        }}
      >
        <div
          className="left-banner"
          style={{
            display: "flex",
            backgroundColor: "#f9a70dff",
            transform: "rotate(-90deg)",
            transformOrigin: "0 0",
            top: `${height - padding}mm`,
            left: `${padding}mm`,
            height: `${leftBannerWidth}mm`,
            width: `${height - 2 * padding}mm`, // 37.125 - 2 * 6 = 25.125
            position: "absolute",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              fontSize: `${2}mm`,
            }}
          >
            www.winefocus.ro
          </p>
        </div>
        <div style={{
          width: "140%",
        }}>
          <p
            style={{
              fontSize: `${largeFontSize}mm`,
            }}
          >
            {producer}
          </p>
          <p
            style={{
              fontWeight: "",
              // fontSize: `${largeFontSize}mm`,
              fontSize: "2mm",
            }}
          >
            {model}
          </p>
          <p
            style={{
              // fontSize: `2.3mm`,
              fontSize: `2mm`,
            }}
          >
            {type}
          </p>
          <hr />
          <p>Importator: Wine Focus Intertrade SRL, Linia de Centura nr 2, Stefanestii de Jos, Ilfov</p>
          <p>Tel.: 0722 345 347</p>
          <p>e-mail: contact@winefocus.ro</p>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0",
              margin: "0",
            }}
          >
            <p
              style={{
                fontSize: `${largeFontSize}mm`,
              }}
            >
              {quantity}
            </p>
            <p
              style={{
                fontSize: `${largeFontSize}mm`,
              }}
            >
             {alcohol &&
              <span> {alcohol}</span>
             }
            </p>
          </div>
          <p>{country}</p>
          <p>{year}</p>
          <p>Conține sulfiți</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              // flexGrow: "1",
              position: "absolute",
              bottom: `${padding - 0.5}mm`,
              width: "2.2cm",
            }}
          >
            <img src={WFLogo} width="18mm" height="18mm" alt="" />
            <img src={AmbalajGarantieLogo} width="23mm" alt="" />
          </div>
        </div>
      </div>
      <div
        style={{
          transform: "rotate(-90deg) ",
          display: "flex",
          alignItems: "end",
          justifyContent: "center",
          marginRight: "3mm",
        }}
      >
        <Barcode value={barcode} format="EAN13" width={1.07} height={67} margin={0} padding={0} />
      </div>
    </div>
  );
}
